import AppWrapper from "../layouts/AppWrapper";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotFoundIcon } from "../assets/SVGs/404.svg";
import { ReactComponent as ButtonArrowIcon } from "../assets/SVGs/button-arrow.svg";

const ErrorPage = () => {
  let navigate = useNavigate();
  return (
    <AppWrapper>
      <section className="py-36">
        <div className="px-5">
          <span className="block w-full mb-6 sm:w-80 sm:mx-auto md:w-96">
            <NotFoundIcon />
          </span>
          <p className="mb-8 font-semibold text-center text-primary-500 sm:text-lg md:text-xl">
            Oops! The page you are looking for does not exist.
          </p>
          <div className="w-48 mx-auto h-14">
            <button
              onClick={() => navigate("/")}
              className="flex items-center justify-center w-full h-full gap-3 px-3 py-2 font-medium transition duration-150 border-none outline-none bg-primary-500 text-black-50 rounded-xl hover:bg-primary-400"
            >
              <span>Go Back Home</span>
              <span className="inline-block w-6">
                <ButtonArrowIcon />
              </span>
            </button>
          </div>
        </div>
      </section>
    </AppWrapper>
  );
};

export default ErrorPage;
