import React from "react";
import { ReactComponent as Logo } from "../assets/SVGs/trava-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../assets/SVGs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/SVGs/instagram.svg";
import { ReactComponent as XIcon } from "../assets/SVGs/x.svg";
import { ReactComponent as TiktokIcon } from "../assets/SVGs/tiktok.svg";

const Footer = () => {
  let navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    {
      icon: <FacebookIcon className="w-6" />,
      link: "https://www.facebook.com/Travaapp",
    },
    {
      icon: <TiktokIcon className="w-6 stroke-" />,
      link: "https://www.tiktok.com/@travaapp",
    },
    {
      icon: <InstagramIcon className="w-6" />,
      link: "https://www.instagram.com/travaapp",
    },
    {
      icon: <XIcon className="w-6" />,
      link: "https://x.com/travaapp_com",
    },
  ];

  const companyLinks = [
    {
      name: "About Us",
      path: "/#about",
    },
    {
      name: "Services ",
      path: "/#services ",
    },
    {
      name: "FAQ ",
      path: "/#faq ",
    },
  ];

  return (
    <footer
      id="contact"
      className="px-5 py-16 bg-primary-500 text-black-200 md:px-14 md:py-20 lg:px-20"
    >
      <section className="grid grid-cols-1 gap-12 mb-14 sm:grid-cols-2 lg:max-w-screen-2xl lg:mx-auto lg:grid-cols-4 lg:gap-5">
        <div className="w-full text-center">
          <span className="inline-block">
            <Logo
              onClick={() => {
                navigate("/");
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              className="mb-4 cursor-pointer w-36 h-40f md:mb-6"
            />
          </span>
        </div>

        <div className="w-full text-center">
          <p className="mb-5 font-semibold uppercase text-black-50">Company</p>
          <ul>
            {companyLinks.map((item, index) => (
              <li key={index}>
                <HashLink
                  to={item?.path}
                  smooth
                  className="inline-block mb-2 text-black capitalize transition hover:text-primary"
                >
                  {item?.name}
                </HashLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full text-center">
          <p className="mb-5 font-semibold uppercase text-black-50">Legal</p>
          <ul>
            <li>
              <HashLink
                to="/privacy-policy"
                smooth
                className="inline-block mb-2 text-black capitalize transition hover:text-primary"
              >
                Privacy Policy
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="text-center ">
          <p className="mb-5 font-semibold uppercase text-black-50 ">Social</p>
          <Link
            to={"mailto:support@travaapp.com"}
            className="inline-block mb-4"
          >
            support@travaapp.com
          </Link>
          <div className="flex items-center justify-center gap-3 mb-6">
            {socialLinks.map((item, index) => (
              <Link
                key={index}
                to={item?.link}
                target="_blank"
                className="flex items-center justify-center transition-colors duration-150 rounded-full text-black-500 bg-black-100 w-11 h-11 hover:bg-black-200"
                rel="noopener"
              >
                {item?.icon}
              </Link>
            ))}
          </div>
        </div>
      </section>
      <p className="text-sm text-center">
        &copy; {currentYear} Trava, All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
