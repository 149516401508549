import { ReactComponent as PlayStoreIcon } from "../assets/SVGs/play-store-logo.svg";
import { Link } from "react-router-dom";

const GooglePlayButton = () => {
  return (
    <Link
      to="https://play.google.com/store/apps/details?id=com.ibranch.trava"
      target="_blank"
      className="border-none outline-none bg-primary-500 text-black-50 w-44 px-5 py-2 rounded-xl flex gap-3 items-center justify-betweenf transition duration-150 sm:w-52 sm:px-8 sm:gap-4"
    >
      <span className="w-5">
        <PlayStoreIcon />
      </span>
      <p className="text-left">
        <span className="text-xs block uppercase sm:text-sm">Get it on</span>
        <span className="font-bold text-sm sm:text-base"> Google Play</span>
      </p>
    </Link>
  );
};

export default GooglePlayButton;
